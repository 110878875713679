import { Image, ImageProps } from 'react-bootstrap';
import styled from 'styled-components';

const MainPicture = styled(Image).attrs(({ fluid, className }: ImageProps) => ({
    fluid: fluid || true,
    className: `${className || ''} w-100` as string,
}))`
    min-height: 18rem;
    object-fit: cover;
`;

export default MainPicture;
