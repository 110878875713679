import H1 from 'components/Layout/H1';
import MainPicture from 'components/Layout/MainPicture';
import LinkButton from 'components/Shared/LinkButton';
import main from 'images/static/covid/gouverneur.jpeg';
import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';

const Covid = (): ReactElement => {
    const { t, i18n } = useTranslation('static');

    return (
        <React.Fragment>
            <Helmet>
                <meta
                    property="og:url"
                    content={`${process.env.REACT_APP_SITE_URL}${
                        i18n.language === 'fr' ? 'conditions-covid-19' : 'covid-19-condition'
                    }`}
                />
                <meta property="og:site_name" content={t('static:covid.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('static:covid.meta.og.title')} />
                <meta property="og:description" content={t('static:covid.meta.og.description')} />
                <meta
                    property="og:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/covid/gouverneur.jpeg`}
                />
                <meta property="twitter:title" content={t('static:covid.meta.twitter.title')} />
                <meta property="twitter:description" content={t('static:covid.meta.twitter.description')} />
                <meta
                    property="twitter:image"
                    content={`${process.env.REACT_APP_SITE_URL}images/static/covid/gouverneur.jpeg`}
                />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('static:covid.meta.title')} />
                <title>{t('static:covid.meta.title')}</title>
                <meta property="description" content={t('static:covid.meta.description')} />
            </Helmet>
            <Container fluid>
                <div>
                    <MainPicture src={main} alt="beach" />
                </div>
            </Container>
            <Container className="g-2">
                <Row className="mb-5">
                    <Col xs={12} className="text-center">
                        <H1>
                            <Trans t={t}>static:covid.title1</Trans>
                        </H1>
                        <p className="mt-2">
                            <Trans t={t}>static:covid.paragraph1</Trans>
                        </p>
                        <LinkButton to={i18n.language === 'fr' ? '/contact' : '/contact-us'}>
                            <Trans t={t}>static:covid.button1</Trans>
                        </LinkButton>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs={12} className="text-center">
                        <H1 as="h2">
                            <Trans t={t}>static:covid.title2</Trans>
                        </H1>
                        <p className="mt-2">
                            <Trans t={t}>static:covid.paragraph2</Trans>
                        </p>
                        <LinkButton href={t('static:covid.insurance_link')} as="a" target="_blank">
                            <Trans t={t}>static:covid.button2</Trans>
                        </LinkButton>
                    </Col>
                </Row>
                <Row className="pb-5">
                    <Col xs={12} className="text-center">
                        <H1 as="h2">
                            <Trans t={t}>static:covid.title3</Trans>
                        </H1>
                        <p className="mt-2">
                            <Trans t={t}>static:covid.paragraph3</Trans>
                        </p>
                        <ul className="text-start">
                            <li>
                                <Trans t={t}>static:covid.bullet1</Trans>
                            </li>
                            <li>
                                <Trans t={t}>static:covid.bullet2</Trans>
                            </li>
                            <li>
                                <Trans t={t}>static:covid.bullet3</Trans>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
};

export default Covid;
