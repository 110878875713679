import { cssBreakpoint } from 'device';
import styled from 'styled-components';
import aroundTitle from 'images/around-title.svg';

const H1 = styled.h1.attrs(() => ({
    className: 'd-inline-block',
}))`
    font-family: ${(props): string => props.theme.ivr.fonts.playfair};
    font-size: 1.3rem;
    font-style: italic;
    font-weight: 700;
    margin: 1.5rem calc(1rem + 33px) 1rem !important;
    position: relative;

    @media screen and ${cssBreakpoint('xl')} {
        font-size: 2rem;
    }

    &:before,
    &:after {
        background-image: url(${aroundTitle});
        content: '';
        height: 22px;
        position: absolute;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        width: 33px;
    }

    &:before {
        left: -50px;
    }

    &:after {
        right: -50px;
    }

    span {
        color: ${(props): string => props.theme.ivr.colors.golden['100']};
        text-transform: uppercase;
    }
`;

export default H1;
