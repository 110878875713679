import { Link } from 'gatsby';
import styled from 'styled-components';

const LinkButton = styled(Link).attrs(({ className }) => ({
    className: `${className || ''} btn text-uppercase` as string,
}))`
    && {
        background: ${(props): string => props.theme.ivr.buttons.submit.base.backgroundColor};
        border-color: ${(props): string => props.theme.ivr.buttons.submit.base.borderColor};
        border-radius: 0.15rem;
        color: ${(props): string => props.theme.ivr.buttons.submit.text};
        padding-bottom: 10px;
        padding-top: 10px;
    }
`;

export default LinkButton;
